<template>
  <div class="edit-author">
    <error :errors="authorError" />
    <author-form action="edit" />
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import authorForm from "../../../components/author/authorForm";
  import Error from "../../../partials/widgets/Error";
  import { mapGetters } from "vuex";
  export default {
    name: "index",
    components: { authorForm, Error },
    computed: {
      ...mapGetters(['authorError'])
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "ئاپتور باشقۇرۇش", route: "list" },
        { title: "ئۇچۇر تەھرىرلەش" }
      ]);
    },
    data(){
      return {};
    },
    methods: {
    }
  }
</script>

<style scoped>

</style>
